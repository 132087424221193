<template>
	<div class="admin-container">
		<Head :MyLocal="location" @newLocal="location=$event" />
		<div class="positon-lg positon-bg">{{$t('etTxt')}}</div>
		<div class="admin-main an-flex">
			<div class="admin-aside admin-tab">
				<div class="positon-lg">{{$t('etTxt')}}</div>
				<div class="acc_navbar">
					<div class="aside-bar">
						<div class="admin-tab-head">
							<div class="admin-tab-item" :class="{'is-active':current==index}"
								v-for="(item,index) in list" :key="index" @click="handleClik(item,index)">
								<!-- <img src="../../assets/photo/icon10.png" alt="" class="ico"> -->
								<span>{{item.title}}</span>
							</div>
						</div>
						<div class="exte-box exte-box-sm">
							<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
								layout="prev, pager, next" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
			<div class="admin-col whole_000 whole_fff">
				<div class="positon oent_box" v-if="detail.info">
					<div class="oent-hd">
						<div class="oent-tit">{{detail.info.title}}</div>
						<div class="oent-time whole-hui">{{detail.info.createTime}}</div>
					</div>
					<div class="oent-bd" v-html="detail.info.content"></div>
					<div class="oent-ft">
						<div class="oent-ewm clearfix">
							<vue-qr :text="qrUrl" :margin="0" :size='300'></vue-qr>
							<p class="whole-hui">{{$t('etTxt2')}}</p>
						</div>
					</div>
					<div class="nav-bottom">
						<div class="nav-bottom__left">
							<el-button v-if="detail.back" @click="prev(detail.back.id)">
								<i class="el-icon-arrow-left"></i> {{$t('prev')}}
								<p>{{detail.back.title}}</p>
							</el-button>
						</div>
						<div class="nav-bottom__right">
							<el-button v-if="detail.next" @click="next(detail.next.id)">
								{{$t('next')}} <i class="el-icon-arrow-right"></i>
								<p>{{detail.next.title}}</p>
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		anPage,
		anMore
	} from '@/api/api/announcement'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import vueQr from 'vue-qr'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			vueQr
		},
		data() {
			return {
				list: [],
				current: 0,
				currentPage: 1,
				pageSize: 10,
				total: 10,
				location: 'en_US',
				id: 0,
				detail: {},
				qrUrl: ""
			};
		},
		created() {
			this.location = localStorage.getItem('lang')
			this.qrUrl = window.location.href
		},
		mounted() {
			this.getList()

		},
		methods: {
			getDetail() {
				anMore({
					id: this.id,
					lang: this.location
				}).then(res => {
					if (res.code == 0) {
						this.detail = res.data
					}
				})
			},
			getList() {
				anPage({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					lang: this.location
				}).then(res => {
					if (res.code == 0) {
						this.list = res.data.content
						this.total = res.data.totalElements
						this.pageSize = res.data.size
						let index = this.current
						this.id = res.data.content[index].id
						this.getDetail()
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val

			},
			handleClik(item, index) {
				this.current = index
				this.$router.push({
					path: '/announcement/' + item.id
				})
				this.getList()
			},
			prev(id) {
				this.id = id;
				this.current += 1
				this.$router.push({
					path: '/announcement/' + id
				})
				this.getDetail()
			},
			next(id) {
				this.id = id;
				this.current -= 1
				this.$router.push({
					path: '/announcement/' + id
				})
				this.getDetail()
			}
		}
	}
</script>
