import request from '../request'


// 根据币种分页查询广告
export function anPage(data) {
	return request({
		url: '/uc/announcement/page',
		method: 'POST',
		data: data
	})
}

export function anMore(data) {
	return request({
		url: '/uc/announcement/more',
		method: 'POST',
		data: data
	})
}